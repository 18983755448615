<template>
  <v-btn
    id="download-document"
    color="secondary"
    class="mt-5 px-5 py-7 d-flex justify-space-between rounded-lg"
    x-large
    block
    @click="download"
    :disabled="loading"
  >
    <div class="d-flex align-center font-weight-regular">
      <v-icon class="mr-2">
        mdi-file-outline
      </v-icon>
      <div
        v-html="$t(document.title)"
        class="download-text"
        :style="{ maxWidth: maxWidth === 'auto' ? '80vw' : `${maxWidth - 128}px`}"
        ></div>
    </div>
    <v-icon color="primary">
      mdi-cloud-download-outline
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDownloadUrl } from '@/api/documents';

export default {
  name: 'DownloadDocument',
  props: {
    document: {
      type: Object,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: [String, Number],
      default: 455,
    },
    isSubscription: {
      type: Boolean,
      default: false,
    },
    asset: {
      type: Object,
      default: () => {
        // return empty object for the default value
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('subscription', [
      'orderId',
    ]),
    downloadName() {
      if (this.isSubscription) {
        return `${this.$t(this.document.title)} - ${this.asset.subscriptionInformation.legalName}.pdf`;
      }
      return `${this.$t(this.document.title)}.pdf`;
    },
  },
  methods: {
    async download() {
      this.loading = true;
      const input = {
        documentTemplateId: this.templateId,
        documentKey: this.document.key,
      };
      if (this.isSubscription) {
        input.orderId = this.orderId;
      }
      const response = await getDownloadUrl(input);
      const link = document.createElement('a');
      link.download = this.downloadName;
      link.href = response.pdfInBase64;
      link.click();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.download-text {
  word-wrap: break-word;
  white-space: initial;
  text-align: left;
  line-height: 24px;
}
@media (max-width: 1200px) {
  .download-text {
    width: 70vw;
  }
}
@media (max-width: 456px) {
  .download-text {
    width: 50vw;
    font-size: 10px;
    line-height: 14px;
  }
}
</style>
